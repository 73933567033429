import { React, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';

import AddCCsCliente from '../../components/addCCsCliente';
import AddContatosCliente from '../../components/addContatosCliente';
import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';

const AdicionarCliente = () => {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [dateTime, setDateTime] = useState(null);
    const [error, setError] = useState(false);
    const [desconto, setDesconto] = useState('');
    const [cnh, setCnh] = useState(null);
    const [fichaMatricula, setFichaMatricula] = useState(null);
    const [procuracao, setProcuracao] = useState(null);
    const [termoAdesao, setTermoAdesao] = useState(null);
    const [cartaoCnpj, setCartaoCnpj] = useState(null);
    const [contratoSocial, setContratoSocial] = useState(null);
    const [listaUnidades, setListaUnidades] = useState([]);
    const [pagCCs, setPagCCs] = useState(false);
    const [pagContato, setPagContato] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    async function adicionarCliente (e) {
        e.preventDefault();
        setIsSubmit(true);

        let data = '';
        if (dateTime) {
            setError(false);
            data = dateTime.toISOString().slice(0, 10);
        } else {
            setError(true);
            setIsSubmit(false);
            return;
        }

        const formData = new FormData();
        formData.append('nome', nome);
        formData.append('email', email);
        formData.append('dataInicio', data);
        formData.append('desconto', desconto);
        formData.append('cnh', cnh);
        formData.append('fichaMatricula', fichaMatricula);
        formData.append('procuracao', procuracao);
        formData.append('termoAdesao', termoAdesao);
        formData.append('cartaoCnpj', cartaoCnpj);
        formData.append('contratoSocial', contratoSocial);

        const response = await fetchFlask('AdicionarCliente', formData);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setPagCCs(true);
        }
        setIsSubmit(false);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            {(!pagCCs && !pagContato) && (
                <>
                <Typography variant='h3'>
                    Adicionar Cliente
                </Typography>

                <form action='' method='POST' id='formAdicionarCliente' onSubmit={adicionarCliente}>
                    <Box className='row'>
                        <Box className='column'>
                            <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                                <TextField
                                    required
                                    label='Nome'
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                />
                            </FormControl>

                            <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                                <TextField
                                    sx={{ width: '300px' }}
                                    required
                                    type='email'
                                    label='Email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormControl>

                            <FormControl sx={{ marginTop: '20px' }}>
                                <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                                    <DatePicker id='data' label='Data de Início'
                                        minDate={new Date('2023-01-02')}
                                        value={dateTime}
                                        onChange={(value) => setDateTime(value)}
                                        slotProps={{
                                            openPickerButton: {
                                                color: 'primary'
                                            }
                                        }}
                                    />
                                    {error && (
                                        <Typography variant='caption' color='red'>
                                            Por favor selecione uma data!
                                        </Typography>
                                    )}
                                </LocalizationProvider>
                            </FormControl>

                            <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                                <TextField
                                    required
                                    type='number'
                                    inputProps={{ min: 0 }}
                                    label='Desconto (%)'
                                    value={desconto}
                                    onChange={(e) => setDesconto(e.target.value)}
                                />
                            </FormControl>
                        </Box>
                        <Box className='column'>
                            <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                <Typography>CNH</Typography>
                                <TextField
                                    type='file'
                                    size='small'
                                    inputProps={{ accept: 'application/pdf, image/*' }}
                                    onChange={(e) => setCnh(e.target.files[0])}
                                    required
                                />
                            </FormControl>

                            <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                <Typography>Ficha de Matrícula</Typography>
                                <TextField
                                    type='file'
                                    size='small'
                                    inputProps={{ accept: 'application/pdf' }}
                                    onChange={(e) => setFichaMatricula(e.target.files[0])}
                                    required
                                />
                            </FormControl>

                            <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                <Typography>Procuração</Typography>
                                <TextField
                                    type='file'
                                    size='small'
                                    inputProps={{ accept: 'application/pdf' }}
                                    onChange={(e) => setProcuracao(e.target.files[0])}
                                    required
                                />
                            </FormControl>

                            <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                <Typography>Termo de Adesão</Typography>
                                <TextField
                                    type='file'
                                    size='small'
                                    inputProps={{ accept: 'application/pdf' }}
                                    onChange={(e) => setTermoAdesao(e.target.files[0])}
                                    required
                                />
                            </FormControl>

                            <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                <Typography>Cartão CNPJ</Typography>
                                <TextField
                                    type='file'
                                    size='small'
                                    inputProps={{ accept: 'application/pdf' }}
                                    onChange={(e) => setCartaoCnpj(e.target.files[0])}
                                />
                            </FormControl>

                            <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                <Typography>Contrato Social</Typography>
                                <TextField
                                    type='file'
                                    size='small'
                                    inputProps={{ accept: 'application/pdf' }}
                                    onChange={(e) => setContratoSocial(e.target.files[0])}
                                />
                            </FormControl>

                            <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                                <SubmitButton isSubmit={isSubmit}>Adicionar Cliente</SubmitButton>
                            </FormControl>
                        </Box>
                    </Box>
                </form>
                </>
            )}
            {(pagCCs && !pagContato) && (
                <AddCCsCliente
                    setListaUnidades={setListaUnidades}
                    setPagContato={setPagContato}
                    setPagCCs={setPagCCs}
                    setMessage={setMessage}
                    setStatus={setStatus}
                    setOpenAlerta={setOpenAlerta}
                    nome={nome}
                />
            )}

            {(!pagCCs && pagContato) && (
                <AddContatosCliente
                    listaUnidades={listaUnidades}
                    setMessage={setMessage}
                    setStatus={setStatus}
                    setOpenAlerta={setOpenAlerta}
                />
            )}
        </Box>
    );
};

export default AdicionarCliente;
