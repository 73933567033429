import { React } from 'react';

import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '../components/protectedRoutes';
import CancelarOS from '../pages/financeiro/cancelarOS';
import CriarContrato from '../pages/financeiro/criarContrato';
import FaturarCliente from '../pages/financeiro/faturarCliente';
import GerarPix from '../pages/financeiro/gerarPix';
import ProrrogarBoleto from '../pages/financeiro/prorrogarBoleto';

const RouteFinanceiro = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute role='Financeiro'/>}>
                <Route path='faturarCliente' element={<FaturarCliente/>}/>
                <Route path='cancelarOS' element={<CancelarOS/>}/>
                <Route path='criarContrato' element={<CriarContrato/>}/>
                <Route path='prorrogarBoleto' element={<ProrrogarBoleto/>}/>
                <Route path='gerarPix' element={<GerarPix/>}/>
            </Route>
        </Routes>
    );
};

export default RouteFinanceiro;
