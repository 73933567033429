import { React, useEffect, useState } from 'react';

import { InputLabel, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';

import { Alerta } from '../../components/alerta';
import { fetchFlask } from '../../utils/fetchFlask';

const Distrato = () => {
    const [tipoDistrato, setTipoDistrato] = useState('');
    const [cliente, setCliente] = useState('');
    const [listaClientes, setListaClientes] = useState([]);
    const [dateTimeDistrato, setDateTimeDistrato] = useState(null);
    const [errorDateDistrato, setErrorDateDistrato] = useState(false);
    const [cc, setCC] = useState('');
    const [motivo, setMotivo] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    useEffect(() => {
        async function getClients () {
            const response = await fetchFlask('getClientes');
            if (response.status === 'error') {
                handleAlerta(response);
            } else {
                const clientsArray = response.dados.map(obj => {
                    const key = Object.keys(obj)[0];
                    const value = obj[key];
                    return { key, value };
                });
                setListaClientes(clientsArray);
            }
        };
        getClients();
    }, []);

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    async function submitDistrato (e) {
        e.preventDefault();

        let data = '';
        if (dateTimeDistrato) {
            setErrorDateDistrato(false);
            data = dateTimeDistrato.toISOString().slice(0, 10);
        } else {
            setErrorDateDistrato(true);
            return;
        }

        if (tipoDistrato === 'CC') {
            setCliente('');
        } else {
            setCC('');
        }

        const body = JSON.stringify({
            cc,
            cliente,
            tipoDistrato,
            data,
            motivo
        });
        const response = await fetchFlask('distrato', body);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);

        if (response.status === 'success') {
            setCC('');
            setCliente('');
            setDateTimeDistrato('');
            setMotivo('');
            setErrorDateDistrato(false);
        }
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Distrato
            </Typography>

            <form action='' method='POST' id='formDistrato' onSubmit={submitDistrato}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <InputLabel id='labelDistrato'>Distrato</InputLabel>
                    <Select
                        id='selectDistrato'
                        labelId='labelDistrato'
                        label='tipoDistrato'
                        value={tipoDistrato}
                        onChange={(e) => setTipoDistrato(e.target.value)}
                        sx={{ width: '220px' }}
                    >
                        <MenuItem value={'CC'}>CC</MenuItem>
                        <MenuItem value={'Cliente'}>Cliente</MenuItem>
                    </Select>
                </FormControl>

                {tipoDistrato === 'CC' && (
                    <>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                required
                                label='Conta Contrato'
                                value={cc}
                                onChange={(e) => setCC(e.target.value)}
                            />
                        </FormControl>
                    </>
                )}

                {tipoDistrato === 'Cliente' && (
                    <>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <InputLabel id='labelCliente'>Cliente</InputLabel>
                            <Select
                                id='selectCliente'
                                labelId='labelCliente'
                                label='Cliente'
                                value={cliente}
                                onChange={(e) => setCliente(e.target.value)}
                                sx={{ width: '300px' }}
                                required
                            >
                                {listaClientes.map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.key}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </>
                )}

                <FormControl sx={{ marginTop: '20px' }} >
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Data Distrato'
                            minDate={new Date('2023-01-02')}
                            value={dateTimeDistrato}
                            onChange={(value) => setDateTimeDistrato(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {errorDateDistrato && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl >

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <InputLabel id='labelCat'>Motivo</InputLabel>
                    <Select
                        id='motivo'
                        labelId='labelCat'
                        label='motivo'
                        sx={{ width: '300px' }}
                        value={motivo}
                        onChange={(e) => setMotivo(e.target.value)}
                        required
                    >
                        <MenuItem value='Inadimplência'>Inadimplência</MenuItem>
                        <MenuItem value='Entrega Imóvel'>Entrega Imóvel</MenuItem>
                        <MenuItem value='Insatisfação'>Insatisfação</MenuItem>
                        <MenuItem value='Geração Própria'>Geração Própria</MenuItem>
                        <MenuItem value='Não Informado'>Não Informado</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <Button type='submit' variant="contained">Distratar</Button>
                </FormControl>
            </form>
        </Box>
    );
};

export default Distrato;
