import { React, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, FormHelperText, Grid, IconButton, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';
import { maskCpfCnpj, maskRG, maskCEP, maskPhone } from '../../utils/maskCpfCnpj';

const createDocuments = () => {
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [clientType, setClientType] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [representativeRG, setRepresentativeRG] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [representativeName, setRepresentativeName] = useState('');
    const [address, setAddress] = useState('');
    const [cep, setCep] = useState('');
    const [rgExp, setRgExp] = useState('');
    const [representativeCpf, setRepresentativeCpf] = useState('');
    const [cpfAddress, setCpfAddress] = useState('');
    const [cpfCep, setCpfCep] = useState('');
    const [discount, setDiscount] = useState('');
    const [cc, setCC] = useState('');
    const [ccAddress, setCCAddress] = useState('');
    const [consumption, setConsumption] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactName, setContactName] = useState('');
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');
    const [neonergiaUF, setNeonergiaUF] = useState('');
    const [rows, setRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [error, setError] = useState(false);
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const columns = [
        {
            field: 'ccCol',
            headerName: 'CC',
            width: 150,
            editable: true
        },
        {
            field: 'addressCol',
            headerName: 'Endereço',
            width: 150,
            editable: true
        },
        {
            field: 'consumptionCol',
            headerName: 'Consumo',
            type: 'number',
            width: 110,
            editable: true
        },
        {
            field: ' ',
            renderCell: (params) => (
                <IconButton edge="end" onClick={() => deleteCC(params.row.id)}>
                    <DeleteIcon />
                </IconButton>
            )
        }
    ];

    const handleRowSelection = (newSelection) => {
        setSelectedRows(newSelection);
    };

    const deleteCC = (id) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    };

    const processRowUpdate = (newRow) => {
        setRows((prevRows) =>
            prevRows.map((row) => (row.id === newRow.id ? newRow : row))
        );
        return newRow;
    };

    const addUnidades = () => {
        if (!cc || !ccAddress || !consumption) {
            alert('Preencha todos os campos para adicionar uma unidade!');
            return;
        }

        setRows([...rows, { id: rows.length + 1, ccCol: cc, addressCol: ccAddress, consumptionCol: consumption }]);
        setCC('');
        setCCAddress('');
        setConsumption('');
    };

    async function submitCreateDocuments (e) {
        e.preventDefault();
        setIsSubmit(true);

        const dictUnits = rows.map(row => ({
            cc: row.ccCol,
            address: row.addressCol,
            consumption: row.consumptionCol
        }));

        const body = JSON.stringify({
            clientType,
            cpfCnpj,
            representativeRG,
            companyName,
            representativeName,
            address,
            cep,
            rgExp,
            neonergiaUF,
            representativeCpf,
            cpfAddress,
            cpfCep,
            discount,
            contactEmail,
            contactName,
            phone1,
            phone2,
            dictUnits
        });

        const response = await fetchFlask('createDocuments', body);
        handleAlerta(response);
        setIsSubmit(false);
    };

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>Criar Documentos Comercial</Typography>

            <form action='' method='POST' id='formNotificarCliente' onSubmit={submitCreateDocuments}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelClientType'>Tipo</InputLabel>
                            <Select
                                id='selectClientType'
                                labelId='labelClientType'
                                label='clientType'
                                value={clientType}
                                onChange={(e) => setClientType(e.target.value)}
                                sx={{ width: '220px' }}
                            >
                                <MenuItem value={'pf'}>Pessoa Física</MenuItem>
                                <MenuItem value={'pj'}>Pessoa Jurídica</MenuItem>
                                <MenuItem value={'cond'}>Condomínio</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {clientType && (
                        <>
                            <Grid item xs={5}>
                                <Paper elevation={3} sx={{ padding: 2, margin: '10px', marginTop: '20px' }}>
                                    <Typography variant='h3' sx={{ marginBottom: '10px' }}>
                                        Cliente
                                    </Typography>
                                    {clientType !== 'pf'
                                        ? (
                                            <>
                                                <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        label='Nome da Empresa'
                                                        value={companyName}
                                                        onChange={(e) => setCompanyName(e.target.value)}
                                                    />
                                                </FormControl>
                                            </>
                                        )
                                        : (
                                            <>
                                                <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        label='Nome do Representante'
                                                        value={representativeName}
                                                        onChange={(e) => setRepresentativeName(e.target.value)}
                                                    />
                                                </FormControl>
                                            </>
                                        )}

                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <TextField
                                                    required
                                                    label='CPF ou CNPJ'
                                                    value={cpfCnpj}
                                                    inputProps={{ maxLength: 18 }}
                                                    onChange={(e) => setCpfCnpj(maskCpfCnpj(e.target.value))}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <TextField
                                                    required
                                                    label='RG'
                                                    value={representativeRG}
                                                    inputProps={{ maxLength: 9 }}
                                                    onChange={(e) => setRepresentativeRG(maskRG(e.target.value))}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <TextField
                                                    required
                                                    label='Orgão Expedidor'
                                                    value={rgExp}
                                                    onChange={(e) => setRgExp(e.target.value)}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                        <TextField
                                            required
                                            label='Endereço'
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </FormControl>

                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <TextField
                                                    required
                                                    label='CEP'
                                                    value={cep}
                                                    inputProps={{ maxLength: 9 }}
                                                    onChange={(e) => setCep(maskCEP(e.target.value))}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <InputLabel id='labelUF'>Estado</InputLabel>
                                                <Select
                                                    required
                                                    id='selectUF'
                                                    label='Estado'
                                                    value={neonergiaUF}
                                                    onChange={(e) => setNeonergiaUF(e.target.value)}
                                                >
                                                    <MenuItem value={'PE'}>Pernambuco</MenuItem>
                                                    <MenuItem value={'RN'}>Rio Grande do Norte</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container sx={{ justifyContent: 'center' }}>
                                        <Grid item xs={3}>
                                            <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                                                <TextField
                                                    required
                                                    label='Desconto'
                                                    value={discount}
                                                    inputProps={{ maxLength: 2 }}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        const numericValue = parseInt(value, 10);

                                                        if ((numericValue >= 20 && numericValue <= 49) || value === '') {
                                                            setError(false);
                                                        } else {
                                                            setError(true);
                                                        }

                                                        setDiscount(value);
                                                    }}
                                                />
                                                {error && (
                                                    <FormHelperText sx={{ textAlign: 'center', color: 'red', margin: '0px' }}>
                                                        Informe um desconto entre 20 e 49.
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    {clientType !== 'pf' && (
                                        <>
                                            <Paper elevation={2} sx={{ padding: 2, marginTop: '20px' }}>
                                                <Typography variant='h5' sx={{ marginBottom: '10px' }}>
                                                    Representante
                                                </Typography>

                                                <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        label='Nome do Representante'
                                                        value={representativeName}
                                                        onChange={(e) => setRepresentativeName(e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        label='CPF do Representante'
                                                        value={representativeCpf}
                                                        inputProps={{ maxLength: 14 }}
                                                        onChange={(e) => setRepresentativeCpf(maskCpfCnpj(e.target.value))}
                                                    />
                                                </FormControl>

                                                {clientType === 'pj' && (
                                                    <>
                                                        <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                            <TextField
                                                                required
                                                                label='Endereço do Representante'
                                                                value={cpfAddress}
                                                                onChange={(e) => setCpfAddress(e.target.value)}
                                                            />
                                                        </FormControl>

                                                        <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                                            <TextField
                                                                required
                                                                label='CEP do Representante'
                                                                value={cpfCep}
                                                                inputProps={{ maxLength: 9 }}
                                                                onChange={(e) => setCpfCep(maskCEP(e.target.value))}
                                                            />
                                                        </FormControl>
                                                    </>
                                                )}
                                            </Paper>
                                        </>
                                    )}

                                    <Paper elevation={2} sx={{ padding: 2, marginTop: '20px' }}>
                                        <Typography variant='h5'>
                                            Contato
                                        </Typography>

                                        <Grid container>

                                            <Grid item xs={10}>
                                                <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        label='Nome'
                                                        value={contactName}
                                                        onChange={(e) => setContactName(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={10}>
                                                <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        type="email"
                                                        label='Email'
                                                        value={contactEmail}
                                                        onChange={(e) => setContactEmail(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        label='Telefone 1'
                                                        inputProps={{ maxLength: 15 }}
                                                        value={phone1}
                                                        onChange={(e) => setPhone1(maskPhone(e.target.value))}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                                    <TextField
                                                        label='Telefone 2'
                                                        inputProps={{ maxLength: 15 }}
                                                        value={phone2}
                                                        onChange={(e) => setPhone2(maskPhone(e.target.value))}
                                                    />
                                                </FormControl>
                                            </Grid>

                                        </Grid>
                                    </Paper>
                                </Paper>
                            </Grid>

                            <Grid item xs={7}>
                                <Paper elevation={3} sx={{ padding: 2, margin: '20px' }}>
                                    <Grid container alignItems="center" justifyContent="space-between" marginBottom={2}>
                                        <Grid item>
                                            <Typography variant='h3' sx={{ marginBottom: '10px' }}>
                                                Unidades
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Paper elevation={1} sx={{ backgroundColor: '#f0f1f383', marginTop: '10px', width: '100%' }}>
                                        <Box sx={{ height: 250 }}>
                                            <DataGrid
                                                rows={rows}
                                                columns={columns}
                                                processRowUpdate={processRowUpdate}
                                                onSelectionModelChange={handleRowSelection}
                                                localeText={{ noRowsLabel: 'Sem Unidades' }}
                                                hideFooter
                                            />
                                        </Box>
                                        {selectedRows.length > 0 && (
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => deleteCC(selectedRows)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                        <Grid container spacing={2} sx={{ padding: 2, justifyContent: 'center' }}>

                                            <Grid item>
                                                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                                    <TextField
                                                        label='Conta Contrato'
                                                        value={cc}
                                                        onChange={(e) => setCC(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        label='Consumo'
                                                        value={consumption}
                                                        onChange={(e) => setConsumption(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        label='Endereço'
                                                        value={ccAddress}
                                                        onChange={(e) => setCCAddress(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Button variant='contained' onClick={addUnidades}>Adicionar Unidade</Button>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Paper>

                                <Grid container spacing={2} sx={{ padding: 2, justifyContent: 'center' }}>
                                    <Grid item xs={6} sx={{ marginTop: '20px' }}>
                                        <FormControl sx={{ display: 'flex' }}>
                                            <SubmitButton isSubmit={isSubmit} >Criar Documentos</SubmitButton>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}

                </Grid>
            </form>
        </Box>
    );
};

export default createDocuments;
