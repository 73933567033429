import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';

const CadastrarCC = () => {
    const [ccAntiga, setCCAntiga] = useState('');
    const [ccNova, setCCNova] = useState('');
    const [instalacao, setInstalacao] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [consorcio, setConsorcio] = useState(true);
    const [documento, setDocumento] = useState('');
    const [notificar, setNotificar] = useState(true);
    const [isSubmit, setIsSubmit] = useState(false);

    async function submitCadastrarCC (e) {
        e.preventDefault();
        setIsSubmit(true);

        const body = JSON.stringify({
            ccAntiga,
            ccNova,
            instalacao,
            documento: consorcio ? '' : documento,
            notificar
        });

        const response = await fetchFlask('cadastrarCC', body);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setCCAntiga('');
            setCCNova('');
            setInstalacao('');
            setDocumento('');
        }
        setIsSubmit(false);
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Alterar CC
                {consorcio ? ' Consórcio' : ' Fora do Consórcio'}
            </Typography>

            <form action='' method='POST' id='formCadastrarCC' onSubmit={submitCadastrarCC}>
                <FormControlLabel label="Consórcio"
                    control={
                        <Switch
                            checked={consorcio}
                            onChange={(e) => setConsorcio(e.target.checked)}
                        />
                    }
                />

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Conta Contrato Antiga'
                        value={ccAntiga}
                        onChange={(e) => setCCAntiga(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Conta Contrato Nova'
                        value={ccNova}
                        onChange={(e) => setCCNova(e.target.value)}
                    />
                </FormControl>

                {consorcio && (
                    <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                        <TextField
                            required
                            label='Instalação'
                            value={instalacao}
                            onChange={(e) => setInstalacao(e.target.value)}
                        />
                    </FormControl>
                )}

                {!consorcio && (
                    <>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                required
                                label='Documento'
                                value={documento}
                                onChange={(e) => setDocumento(e.target.value)}
                            />
                        </FormControl>

                        <FormControlLabel
                            sx={{ display: 'block', marginTop: '20px' }}
                            label="Notificar Cliente"
                            control = {<Checkbox
                                label='Notificar'
                                checked={notificar}
                                onChange={() => setNotificar(!notificar)}
                            />}
                        />
                    </>
                )}

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit}>Alterar CC</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default CadastrarCC;
