import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';

import SubmitButton from './submitButton';
import { maskCpfCnpj } from '../utils/maskCpfCnpj';
import { fetchFlask } from '../utils/fetchFlask';

const AddContatosCliente = (props) => {
    const [unidadesContato, setUnidadesContato] = useState([]);
    const [ddd, setDDD] = useState('');
    const [dddDois, setDDDdois] = useState('');
    const [telefone, setTelefone] = useState('');
    const [telefoneDois, setTelefoneDois] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [emailDois, setEmailDois] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [checked, setChecked] = useState('');
    const [contatosAdicionados, setContatosAdicionados] = useState(props.listaUnidades.map(() => false));
    const [isSubmit, setIsSubmit] = useState(false);

    const handleSelectAll = () => {
        if (unidadesContato.length > 0) {
            alert('Para selecionar todos, apague os contatos que já foram inseridos!');
            return;
        }
        if (checked.length === props.listaUnidades.length) {
            setChecked([]);
        } else {
            setChecked(props.listaUnidades.slice());
        }
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const apagarContato = (value, index) => {
        const novoContatosAdd = [...contatosAdicionados];
        novoContatosAdd[index] = false;
        setContatosAdicionados(novoContatosAdd);

        setUnidadesContato((prevList) => {
            return prevList.filter((item) => item.unidade !== value.split(' | ')[2]);
        });
    };

    const addContatoUnidades = () => {
        if (checked.length === 0) {
            alert('selecione pelo menos uma unidade!');
            return;
        }
        if (!ddd || !telefone || !nome || !email || !cpfCnpj) {
            alert('Preencha Todas as Informações Obrigatórias');
            return;
        }
        const listaUnidades = [...unidadesContato];
        const novoContatosAdd = [...contatosAdicionados];
        checked.forEach((unidade) => {
            const dictValores = {
                unidade: unidade.split(' | ')[2],
                ddd,
                telefone,
                dddDois,
                telefoneDois,
                nome,
                email,
                emailDois,
                cpfCnpj
            };
            listaUnidades.push(dictValores);

            const indexUnidade = props.listaUnidades.indexOf(unidade);
            novoContatosAdd[indexUnidade] = true;
        });
        setChecked([]);
        setUnidadesContato(listaUnidades);
        setContatosAdicionados(novoContatosAdd);
        setDDD('');
        setTelefone('');
        setDDDdois('');
        setTelefoneDois('');
        setNome('');
        setEmail('');
        setEmailDois('');
        setCpfCnpj('');
    };

    async function submitContatos (e) {
        e.preventDefault();
        setIsSubmit(true);

        if (props.listaUnidades.length !== unidadesContato.length) {
            alert('Ainda tem unidades sem informações de contato!!');
            setIsSubmit(false);
            return;
        }

        const body = JSON.stringify({
            unidadesContato
        });
        const response = await fetchFlask('AdicionarClienteContato', body);
        props.setMessage(response.message);
        props.setStatus(response.status);
        props.setOpenAlerta(true);
        if (response.status === 'success') {
            setTimeout(() => {
                window.location.reload();
              }, 3000);
        }
        setIsSubmit(false);
    };

    return (
        <Box>
            <Typography variant='h3'>Adicionar Contatos Cliente</Typography>

            <form method='POST' onSubmit={submitContatos}>
                <Box className='row'>
                    <Box className='column'>
                        <List sx={{ maxWidth: '600px' }}>
                            <ListItem disablePadding>
                                <ListItemButton dense onClick={handleSelectAll}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={checked.length === props.listaUnidades.length}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Selecionar Tudo" />
                                </ListItemButton>
                            </ListItem>
                            {props.listaUnidades.map((item, index) => (
                                <ListItem
                                    key={index}
                                    disablePadding
                                    secondaryAction={
                                        contatosAdicionados[index] &&
                                        <IconButton onClick={() => apagarContato(item, index)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    }
                                >
                                    <ListItemButton dense onClick={handleToggle(item)}
                                        disabled={contatosAdicionados[index]}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={checked.indexOf(item) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={item} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Box className='column'>
                        <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                            <TextField
                                label='DDD*'
                                value={ddd}
                                onChange={(e) => setDDD(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                            <TextField
                                label='Telefone*'
                                value={telefone}
                                onChange={(e) => setTelefone(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                            <TextField
                                label='DDD 2'
                                value={dddDois}
                                onChange={(e) => setDDDdois(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                            <TextField
                                label='Telefone 2'
                                value={telefoneDois}
                                onChange={(e) => setTelefoneDois(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                            <TextField
                                label='Nome*'
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                            <TextField
                                label='E-mail*'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                            <TextField
                                label='E-mail 2'
                                value={emailDois}
                                onChange={(e) => setEmailDois(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                            <TextField
                                label='CPF ou CNPJ*'
                                value={cpfCnpj}
                                onChange={(e) => setCpfCnpj(maskCpfCnpj(e.target.value))}
                            />
                        </FormControl>

                        <Tooltip title='Adicionar Contatos nas Unidades Selecionadas'>
                            <IconButton sx={{ marginTop: '5px', backgroundColor: 'lightGrey' }} onClick={addContatoUnidades}>
                                <AddIcon sx={{ color: 'blue' }} fontSize='large' />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit}>Adicionar Contatos</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default AddContatosCliente;
