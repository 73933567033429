import React, { useContext, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import { Fab } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import DadosUsuario from '../../utils/context';
import { fetchFlask } from '../../utils/fetchFlask';

const ComercialCRM = () => {
    const [cliente, setCliente] = useState('');
    const [desconto, setDesconto] = useState('');
    const [cc, setCC] = useState('');
    const [endereco, setEndereco] = useState('');
    const [consumos, setConsumos] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [DDD, setDDD] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [DDD2, setDDD2] = useState('');
    const [phoneNumber2, setPhoneNumber2] = useState('');

    const [rows, setRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [openAlerta, setOpenAlerta] = useState(false);
    const [contactOmie, setContactOmie] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const emailPortal = useContext(DadosUsuario).email;

    const sellerData = {
        'carloscavalcanti@settaenergia.com.br': 'Carlos Cavalcanti',
        'renatojeronimo@settaenergia.com.br': 'Renato Jeronimo',
        'fabioabrahao@settaenergia.com.br': 'Fabio Abrahão',
        'fernandoporto@settaenergia.com.br': 'Fernando Porto',
        'davidalves@settaenergia.com.br': 'David Alves'
      };
    const isSeller = Object.keys(sellerData).includes(emailPortal);
    const sellerName = isSeller ? sellerData[emailPortal] : 'Carlos Cavalcanti';
    const sellerEmail = isSeller ? emailPortal : 'carloscavalcanti@settaenergia.com.br';

    const columns = [
        // { field: 'id', headerName: 'ID', width: 90, editable: false },
        {
            field: 'ccCol',
            headerName: 'CC',
            width: 150,
            editable: true
        },
        {
            field: 'enderecoCol',
            headerName: 'Endereço',
            width: 150,
            editable: true
        },
        {
            field: 'consumoCol',
            headerName: 'Consumo',
            type: 'number',
            width: 110,
            editable: true
        },
        {
            field: ' ',
            renderCell: (params) => (
                <IconButton edge="end" onClick={() => deleteCC(params.row.id)}>
                    <DeleteIcon />
                </IconButton>
            )
        }
    ];

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    const addUnidades = () => {
        if (!cc || !endereco || !consumos) {
            alert('Preencha todos os campos para adicionar uma unidade!');
            return;
        }

        let consumoTotal = 0;
        const consumoLinhas = consumos.split('\n').filter(item => item !== '');
        for (const consumo of consumoLinhas) {
            const consumoValue = parseFloat(consumo.replace(',', '.'));
            if (isNaN(consumoValue)) {
                alert('Insira somente números como consumo!!!');
                return;
            }
            consumoTotal += consumoValue;
        };

        const consumoMedio = (consumoTotal / consumoLinhas.length).toFixed(2);

        setRows([...rows, { id: rows.length + 1, ccCol: cc, enderecoCol: endereco, consumoCol: consumoMedio }]);
        setCC('');
        setEndereco('');
        setConsumos('');
    };

    const deleteCC = (id) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    };

    const handleContactOmieToggle = () => {
        setContactOmie(prevState => !prevState);
    };

    const handleRowSelection = (newSelection) => {
        setSelectedRows(newSelection);
    };

    const processRowUpdate = (newRow) => {
        setRows((prevRows) =>
            prevRows.map((row) => (row.id === newRow.id ? newRow : row))
        );
        return newRow;
    };

    async function submitProposta (e) {
        e.preventDefault();
        setIsSubmit(true);

        if (rows.length === 0) {
            alert('Por favor insira pelo menos uma unidade!');
            return;
        }

        const dictUnidades = rows.map(row => ({
            cc: row.ccCol,
            endereco: row.enderecoCol,
            consumo: row.consumoCol
        }));

        const body = JSON.stringify({
            cliente,
            desconto,
            unidades: dictUnidades
        });
        const response = await fetchFlask('criarProposta', body);
        if (response.status !== 'error') {
            const link = document.createElement('a');
            link.href = response.message;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setMessage('Sua Proposta está sendo Baixada!!');
            setStatus(response.status);
            setOpenAlerta(true);
        } else {
            setMessage(response.message);
            setStatus(response.status);
            setOpenAlerta(true);
        }
        setIsSubmit(false);
    }

    async function submitContato (e) {
        e.preventDefault();
        setIsSubmit(true);

        const body = JSON.stringify({
            firstName,
            lastName,
            email,
            sellerEmail,
            DDD,
            phoneNumber,
            DDD2,
            phoneNumber2
        });

        const response = await fetchFlask('createCrmContact', body);
        handleAlerta(response);
        if (response.message === 'success') {
            setFirstName('');
            setLastName('');
            setEmail('');
            setDDD('');
            setPhoneNumber('');
            setDDD2('');
            setPhoneNumber2('');
        }
        setIsSubmit(false);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Grid container spacing={2}>
                <Grid item id='proposta' xs={12} md={contactOmie ? 4 : 11}>
                    <form method='POST' onSubmit={submitProposta}>
                        <Paper elevation={3} sx={{ padding: 2, marginTop: '30px' }}>
                            <Grid container alignItems="center" justifyContent="space-between" marginBottom={2}>
                                <Grid item>
                                    <Typography variant='h3' sx={{ marginBottom: '10px' }}>
                                        Criar Proposta
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ marginRight: 0 }}>
                                    <Fab color='primary' variant='extended' aria-label='add' onClick={handleContactOmieToggle}>
                                        {contactOmie
                                            ? <> <RemoveIcon /> Criar Proposta </>
                                            : <> <AddIcon /> Criar Contato no Omie </>
                                        }
                                    </Fab>
                                </Grid>
                            </Grid>
                            {!contactOmie &&
                                <>
                                    <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <Grid item>
                                            <FormControl>
                                                <TextField
                                                    required
                                                    label='Cliente'
                                                    value={cliente}
                                                    onChange={(e) => setCliente(e.target.value)}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <TextField
                                                    required
                                                    label='Desconto (%)'
                                                    type='number'
                                                    inputProps={{ min: 0 }}
                                                    value={desconto}
                                                    onChange={(e) => setDesconto(e.target.value)}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Paper elevation={1} sx={{ backgroundColor: '#f0f1f383', marginTop: '10px', width: '100%' }}>
                                        <Box sx={{ height: 250 }}>
                                            <DataGrid
                                                rows={rows}
                                                columns={columns}
                                                processRowUpdate={processRowUpdate}
                                                onSelectionModelChange={handleRowSelection}
                                                localeText={{ noRowsLabel: 'Sem Unidades' }}
                                                hideFooter
                                            />
                                        </Box>
                                        {selectedRows.length > 0 && (
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => deleteCC(selectedRows)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                        <Grid container spacing={2} sx={{ padding: 2, alignItems: 'center', justifyContent: 'center' }}>
                                            <Grid item>
                                                <Grid item>
                                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                                        <TextField
                                                            label='Conta Contrato'
                                                            value={cc}
                                                            onChange={(e) => setCC(e.target.value)}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            label='Endereço'
                                                            value={endereco}
                                                            onChange={(e) => setEndereco(e.target.value)}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        label='Consumos'
                                                        value={consumos}
                                                        multiline
                                                        maxRows={4}
                                                        minRows={4}
                                                        onChange={(e) => setConsumos(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Button variant='contained' onClick={addUnidades}>Adicionar Unidade</Button>
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                    <FormControl sx={{ marginTop: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <SubmitButton isSubmit={isSubmit} >Criar Proposta</SubmitButton>
                                    </FormControl>
                                    </>
                            }
                        </Paper>
                    </form>
                </Grid>

                {contactOmie &&
                    <Grid id='contato' item xs={12} md={8}>
                        <form method='POST' onSubmit={submitContato}>
                            <Paper elevation={3} sx={{ backgroundColor: '#f0f1f33b', padding: 2, marginTop: '30px' }}>
                                <Typography variant='h3' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    Criar Contato No Omie
                                </Typography>

                                <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid item md={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                required
                                                label='Nome'
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                required
                                                label='Sobrenome'
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <FormControl fullWidth>
                                            <TextField
                                                required
                                                type='email'
                                                label='Email'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label='Vendedor'
                                                value={sellerName}
                                                disabled={true}
                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>

                                <Grid container spacing={1} sx={{ marginTop: '10px', alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid item md={2} lg={2} sx={{ maxWidth: '20px' }}>
                                        <FormControl>
                                            <TextField
                                                id='ddd'
                                                required
                                                label='DDD'
                                                value={DDD}
                                                onChange={(e) => setDDD(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id='phone'
                                                required
                                                label='Telefone'
                                                value={phoneNumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} sx={{ marginTop: '1px', alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid item md={2}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label='DDD2'
                                                value={DDD2}
                                                onChange={(e) => setDDD2(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label='Telefone 2'
                                                value={phoneNumber2}
                                                onChange={(e) => setPhoneNumber2(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <FormControl sx={{ marginTop: '30px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <SubmitButton isSubmit={isSubmit} >Criar Contato</SubmitButton>
                                </FormControl>

                            </Paper>
                        </form>
                    </Grid>
                }

            </Grid>
        </Box>
    );
};

export default ComercialCRM;
