import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';

const FaturarCliente = () => {
    const [cc, setCC] = useState('');
    const [dateTimePeriodo, setDateTimePeriodo] = useState(null);
    const [errorPeriodo, setErrorPeriodo] = useState(false);
    const [valorCelpe, setValorCelpe] = useState('');
    const [valorCobrar, setValorCobrar] = useState('');
    const [dateTimeVencimento, setDateTimeVencimento] = useState(null);
    const [errorVencimento, setErrorVencimento] = useState(false);
    const [categoria, setCategoria] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    async function submitFaturarCliente (e) {
        e.preventDefault();
        setIsSubmit(true);

        let periodo = '';
        if (dateTimePeriodo) {
            setErrorPeriodo(false);
            const ano = dateTimePeriodo.getFullYear();
            const mes = dateTimePeriodo.getMonth() + 1;
            periodo = ano + '-' + mes;
        } else {
            setErrorPeriodo(true);
            setIsSubmit(false);
            return;
        }

        let vencimento = '';
        if (dateTimeVencimento) {
            setErrorVencimento(false);
            vencimento = dateTimeVencimento.toISOString().slice(0, 10);
        } else {
            setErrorVencimento(true);
            setIsSubmit(false);
            return;
        }

        const body = JSON.stringify({
            cc,
            periodo,
            valorCelpe,
            valorCobrar,
            vencimento,
            categoria
        });
        const response = await fetchFlask('faturarCliente', body);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setCC('');
            setValorCelpe('');
            setValorCobrar('');
            setCategoria('');
            setDateTimePeriodo(null);
            setDateTimeVencimento(null);
        }
        setIsSubmit(false);
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Faturar Cliente
            </Typography>

            <form action='' method='POST' id='formFaturarCliente' onSubmit={submitFaturarCliente}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Conta Contrato'
                        value={cc}
                        onChange={(e) => setCC(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ marginTop: '20px' }}>
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Período'
                            views={['month', 'year']}
                            openTo='month'
                            minDate={new Date('2023-01-02')}
                            maxDate={new Date()}
                            value={dateTimePeriodo}
                            onChange={(value) => setDateTimePeriodo(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {errorPeriodo && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Valor Celpe'
                        type='number'
                        inputProps={{ step: 0.01, min: 0 }}
                        value={valorCelpe}
                        onChange={(e) => setValorCelpe(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Valor a Cobrar'
                        type='number'
                        inputProps={{ step: 0.01, min: 0 }}
                        value={valorCobrar}
                        onChange={(e) => setValorCobrar(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ marginTop: '20px' }} >
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Vencimento'
                            minDate={new Date('2023-01-02')}
                            value={dateTimeVencimento}
                            onChange={(value) => setDateTimeVencimento(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {errorVencimento && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl >

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <InputLabel id='labelCategoria'>Categoria</InputLabel>
                    <Select
                        labelId='labelCategoria'
                        label='Categoria'
                        sx={{ width: '300px' }}
                        value={categoria}
                        onChange={(e) => setCategoria(e.target.value)}
                        required
                    >
                        <MenuItem value='UC - COMPENSAÇÃO' >UC - COMPENSAÇÃO</MenuItem>
                        <MenuItem value='UC - REPASSE' >UC - REPASSE</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit}>Faturar Cliente</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default FaturarCliente;
