import { React, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';
import { maskCpfCnpj } from '../../utils/maskCpfCnpj';

const AdicionarCC = () => {
    const [listaClientes, setListaClientes] = useState([]);
    const [cliente, setCliente] = useState('');
    const [cc, setCC] = useState('');
    const [unidade, setUnidade] = useState('');
    const [contaEnergia, setContaEnergia] = useState(null);
    const [ddd, setDDD] = useState('');
    const [telefone, setTelefone] = useState('');
    const [telefoneDois, setTelefoneDois] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [emailDois, setEmailDois] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [consumoMedio, setConsumoMedio] = useState('');
    const [distribuidora, setDistribuidora] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const contaRef = useRef(null);

    useEffect(() => {
        async function getClients () {
            const response = await fetchFlask('getClientes');
            if (response.status === 'error') {
                handleAlerta(response);
            } else {
                const clientsArray = response.dados.map(obj => {
                    const key = Object.keys(obj)[0];
                    const value = obj[key];
                    return { key, value };
                });
                setListaClientes(clientsArray);
            }
        };
        getClients();
    }, []);

    async function submitAdicionarCC (e) {
        e.preventDefault();
        setIsSubmit(true);

        const formData = new FormData();
        formData.append('cliente', cliente);
        formData.append('cc', cc);
        formData.append('unidade', unidade);
        formData.append('ddd', ddd);
        formData.append('telefone', telefone);
        formData.append('telefoneDois', telefoneDois);
        formData.append('nome', nome);
        formData.append('email', email);
        formData.append('emailDois', emailDois);
        formData.append('cpfCnpj', cpfCnpj);
        formData.append('consumoMedio', consumoMedio);
        formData.append('distribuidora', distribuidora);
        formData.append('contaEnergia', contaEnergia);

        const response = await fetchFlask('adicionarCC', formData);

        handleAlerta(response);
        if (response.status === 'success') {
            setCliente('');
            setCC('');
            setUnidade('');
            setNome('');
            setDDD('');
            setTelefone('');
            setTelefoneDois('');
            setEmail('');
            setEmailDois('');
            setCpfCnpj('');
            setConsumoMedio('');
            setDistribuidora('');
            setContaEnergia(null);
            contaRef.current.value = '';
        }
        setIsSubmit(false);
    };

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Adicionar CC
            </Typography>

            <form action='' method='POST' id='formCadastrarCC' onSubmit={submitAdicionarCC}>
                <Box className='row'>
                    <Box className='column'>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <InputLabel id='labelCliente'>Cliente</InputLabel>
                            <Select
                                id='selectCliente'
                                labelId='labelCliente'
                                label='Cliente'
                                value={cliente}
                                onChange={(e) => setCliente(e.target.value)}
                                sx={{ width: '300px' }}
                                required
                            >
                                {listaClientes.map((item) => (
                                    <MenuItem key={item.value} value={item.key}>{item.key}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                required
                                label='Conta Contrato'
                                value={cc}
                                onChange={(e) => setCC(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelDistribuidora'>Distribuidora</InputLabel>
                            <Select
                                id='selectDistribuidora'
                                labelId='labelDistribuidora'
                                label='Distribuidora'
                                value={distribuidora}
                                onChange={(e) => setDistribuidora(e.target.value)}
                                sx={{ width: '220px' }}
                                required
                            >
                                <MenuItem value={'COSERN'}>COSERN</MenuItem>
                                <MenuItem value={'ENERGISA PB'}>ENERGISA PB</MenuItem>
                                <MenuItem value={'NEOENERGIA PE'}>NEOENERGIA PE</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                required
                                label='Unidade'
                                value={unidade}
                                onChange={(e) => setUnidade(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <Typography>Conta de Energia</Typography>
                            <TextField
                                type='file'
                                size='small'
                                inputRef={contaRef}
                                inputProps={{ accept: 'application/pdf, image/*' }}
                                onChange={(e) => setContaEnergia(e.target.files[0])}
                            />
                        </FormControl>
                    </Box>
                    <Box className='column'>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                required
                                label='Nome'
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                required
                                label='DDD'
                                value={ddd}
                                onChange={(e) => setDDD(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                required
                                label='Telefone'
                                value={telefone}
                                onChange={(e) => setTelefone(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='Telefone 2'
                                value={telefoneDois}
                                onChange={(e) => setTelefoneDois(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                required
                                label='Email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='Email 2'
                                value={emailDois}
                                onChange={(e) => setEmailDois(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                required
                                label='CPF ou CNPJ'
                                value={cpfCnpj}
                                onChange={(e) => setCpfCnpj(maskCpfCnpj(e.target.value))}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                required
                                label='Consumo Médio'
                                value={consumoMedio}
                                onChange={(e) => setConsumoMedio((e.target.value).replace(',', '.'))}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <SubmitButton isSubmit={isSubmit}>Adicionar CC</SubmitButton>
                        </FormControl>
                    </Box>
                </Box>
            </form>
        </Box>
    );
};

export default AdicionarCC;
