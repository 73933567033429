import { React, useState, useRef } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import SubmitButton from './submitButton';
import { fetchFlask } from '../utils/fetchFlask';

const AddCCsCliente = (props) => {
    const [cc, setCC] = useState('');
    const [unidade, setUnidade] = useState('');
    const [consumoMedio, setConsumoMedio] = useState('');
    const [contaEnergia, setContaEnergia] = useState(null);
    const [listaContas, setListaContas] = useState([]);
    const [listaCCs, setListaCCs] = useState([]);
    const [distribuidora, setDistribuidora] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);

    const contaRef = useRef(null);

    const addCC = () => {
        if (!cc || !unidade || !distribuidora || !consumoMedio) {
            alert('Preencha todos os campos para adiconar uma CC!');
        } else {
            let fileName = 'Não tem Conta de Energia';
            if (contaEnergia) {
                fileName = cc + '.' + contaEnergia.type.split('/')[1];

                setListaContas([...listaContas,
                    new File([contaEnergia], fileName, { type: contaEnergia.type })
                ]);
                setContaEnergia(null);
                contaRef.current.value = '';
            } else {
                setListaContas([...listaContas, fileName]);
            }

            setListaCCs([...listaCCs, cc + ' | ' + distribuidora + ' | ' + unidade + ' | ' + consumoMedio + ' | ' + fileName]);
            setCC('');
            setUnidade('');
            setConsumoMedio('');
            setDistribuidora('');
        }
    };

    const deleteCC = (index) => {
        setListaCCs((prevList) => {
            return prevList.filter((item, i) => i !== index);
        });
        setListaContas((prevList) => {
            return prevList.filter((item, i) => i !== index);
        });
    };

    async function submitCCs (e) {
        e.preventDefault(e);
        setIsSubmit(true);

        if (listaCCs.length === 0) {
            alert('Insira pelo menos uma Conta Contrato!');
            setIsSubmit(false);
            return;
        }

        if (cc || unidade) {
            alert('Campo de Conta Contrato e Unidade não estão vazios! Verifique Se todas as CCs foram inseridas!');
            setIsSubmit(false);
            return;
        }

        const listaUnidadesContatos = listaCCs.map(item => {
            const partes = item.split(' | ');
            return partes.slice(0, 3).join(' | ');
        });

        const formData = new FormData();
        formData.append('nome', props.nome);
        listaCCs.forEach((item) => {
            formData.append('listaCCUnidade', item);
        });
        for (let i = 0; i < listaContas.length; i++) {
            if (listaContas[i] !== 'Não tem Conta de Energia') {
                formData.append('contasEnergia', listaContas[i]);
            }
        };

        const response = await fetchFlask('AdicionarClienteCCs', formData);
        props.setMessage(response.message);
        props.setStatus(response.status);
        props.setOpenAlerta(true);
        if (response.status === 'success') {
            props.setListaUnidades(listaUnidadesContatos);
            props.setPagContato(true);
            props.setPagCCs(false);
        }
        setIsSubmit(false);
    };

    return (
        <Box>
            <Typography variant='h3'>Adicionar CCs Cliente</Typography>

            <form method='POST' onSubmit={submitCCs}>
                <Box className='row'>
                    <Box className='column'>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='Conta Contrato'
                                value={cc}
                                onChange={(e) => setCC(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelDistribuidora'>Distribuidora</InputLabel>
                            <Select
                                id='selectDistribuidora'
                                labelId='labelDistribuidora'
                                label='Distribuidora'
                                value={distribuidora}
                                onChange={(e) => setDistribuidora(e.target.value)}
                                sx={{ width: '220px' }}
                            >
                                <MenuItem value={'COSERN'}>COSERN</MenuItem>
                                <MenuItem value={'ENERGISA PB'}>ENERGISA PB</MenuItem>
                                <MenuItem value={'NEOENERGIA PE'}>NEOENERGIA PE</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='Unidade'
                                value={unidade}
                                onChange={(e) => setUnidade(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='Consumo Médio'
                                value={consumoMedio}
                                onChange={(e) => setConsumoMedio((e.target.value).replace(',', '.'))}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <Typography>Conta de Energia</Typography>
                            <TextField
                                type='file'
                                size='small'
                                inputRef={contaRef}
                                inputProps={{ accept: 'application/pdf, image/*' }}
                                onChange={(e) => setContaEnergia(e.target.files[0])}
                            />
                        </FormControl>

                        <Tooltip title='Adicionar CC e Unidade'>
                            <IconButton sx={{ marginTop: '5px', backgroundColor: '#C4C4C4' }} onClick={addCC}>
                                <AddIcon sx={{ color: 'blue' }} fontSize='large' />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box className='column'>
                        <Typography variant='h5'>
                            Contas Contratos:
                        </Typography>
                        <List dense={true} sx={{ width: 500 }}>
                            {listaCCs.map((item, index) => (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                        <IconButton edge="end" onClick={() => deleteCC(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText>
                                        {item}
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </List>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <SubmitButton isSubmit={isSubmit}>Adicionar CCs</SubmitButton>
                        </FormControl>
                    </Box>
                </Box>
            </form>
        </Box>
    );
};

export default AddCCsCliente;
