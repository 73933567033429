export const maskCpfCnpj = (texto) => {
    texto = texto.replace(/\D/g, '');

    if (texto.length <= 11) {
      texto = texto.replace(/(\d{3})(\d)/, '$1.$2');
      texto = texto.replace(/(\d{3})(\d)/, '$1.$2');
      texto = texto.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else if (texto.length <= 14) {
      texto = texto.replace(/^(\d{2})(\d)/, '$1.$2');
      texto = texto.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      texto = texto.replace(/\.(\d{3})(\d)/, '.$1/$2');
      texto = texto.replace(/(\d{4})(\d)/, '$1-$2');
    } else {
      texto = texto.replace(/\d/g, texto);
    }

    return texto;
};

export const maskRG = (texto) => {
  texto = texto.replace(/\D/g, '');

  if (texto.length <= 9) {
    texto = texto.replace(/(\d{1})(\d)/, '$1.$2');
    texto = texto.replace(/(\d{3})(\d)/, '$1.$2');
    texto = texto.replace(/(\d{3})(\d{1,3})$/, '$1.$2');
  }

  return texto;
};

export const maskCEP = (texto) => {
  texto = texto.replace(/\D/g, '');

  if (texto.length <= 8) {
    texto = texto.replace(/(\d{5})(\d)/, '$1-$2');
  }

  return texto;
};

export const maskPhone = (texto) => {
  texto = texto.replace(/\D/g, '');

  if (texto.length <= 11) {
    texto = texto.replace(/(\d{2})(\d)/, '($1) $2');
    texto = texto.replace(/(\d{5})(\d)/, '$1-$2');
  }

  return texto;
};
