import { getToken, removeToken } from './loginTokens';

export async function fetchFlask (api, body = null, blob = false) {
    const loginToken = getToken();

    const trimStrings = (obj) => {
        if (obj instanceof FormData) {
            const newBody = new FormData();
            for (const pair of obj.entries()) {
                let [key, value] = pair;
                if (typeof value === 'string') {
                    value = value.trim();
                }
                newBody.append(key, value);
            }
            body = newBody;
        } else if (Array.isArray(obj)) {
                for (let i = 0; i < obj.length; i++) {
                    if (typeof obj[i] === 'string') {
                        obj[i] = obj[i].trim();
                    } else if (typeof obj[i] === 'object') {
                        trimStrings(obj[i]);
                    }
                }
        } else {
            if (typeof obj === 'string') {
                obj = JSON.parse(obj);
            };
            for (const key in obj) {
                if (typeof obj[key] === 'string') {
                    obj[key] = obj[key].trim();
                } else if (typeof obj[key] === 'object') {
                    trimStrings(obj[key]);
                }
            }
            body = JSON.stringify(obj);
        }
    };

    const options = {
        headers: {
            Authorization: 'Bearer ' + loginToken
        }
    };
    if (body) {
        options.method = 'POST';
        trimStrings(body);
        options.body = body;
        if (!(body instanceof FormData)) {
            options.headers['Content-Type'] = 'application/json';
        }
    }

    try {
        const response = await fetch('https://api.settacorporativo.com/' + api, options);

        if (blob) {
            return await response.blob();
        }

        const responseJson = await response.json();
        if ('msg' in responseJson) {
            alert('Sua sessão expirou! Por favor faça login novamente!');
            removeToken();
        } else {
            return responseJson;
        }
    } catch (error) {
        console.error('Error:', error);
        return { message: 'Error: Falha no Servidor!', status: 'error' };
    }
}
