import { React } from 'react';

import { Route, Routes } from 'react-router-dom';

import CreateDocuments from '../pages/comercial/criarDocumentos';
import AdicionarCC from '../pages/gestaoEnergia/adicionarCC';
import ProtectedRoute from '../components/protectedRoutes';
import AdicionarCliente from '../pages/comercial/adicionarCliente';

const RouteComercialInterno = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute role='Interno'/>}>
                <Route path='adicionarCliente' element={<AdicionarCliente/>}/>
                <Route path='createDocuments' element={<CreateDocuments/>}/>
                <Route path='adicionarCC' element={<AdicionarCC/>}/>
            </Route>
        </Routes>
    );
};

export default RouteComercialInterno;
